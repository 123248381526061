(function($){

  // ---------- Header START
  $.widget('zn.navigate', {

    _create: function() {
			
			var $this = this;
			this.base_url 			= '/fr/efrontend/navigate_';			
			this.form 					= $($('.form-wrapper').get(0));
			this.control_medias	= false;
			this.INFOCENTRE 		= false;
			
			if($('#claims_listing').length) {
				this._initIndex();
				return;
			}
			
			if(!this.form || (this.form && !this.form.data('form'))){
				return;
			}
			
			var formToInit = this.form.data('form').toUpperCase();
			
			var base_form = 'claimsav';
			
			if(this.form.attr('id').indexOf('claimdealer') != -1) {
				base_form = 'claimdealer';
			}
			if(this.form.attr('id').indexOf('claimimport') != -1) {
				base_form = 'claimimport';
			}
		
			/* Build base url for ajax calls
			----------------------------------*/	
			this.base_url += base_form + '/';			
		
			/* Init js actions in Add screens
			----------------------------------*/
			/*if($('section.claim-add').length) {
				this._initScreenAdd();
				return;
			}*/
			
			if(base_form == 'claimdealer' || base_form == 'claimimport') {
				this.control_medias = true;				
			}
		
			/* Init current form actions
			----------------------------------*/
			this['_init'+formToInit]();
		
			/* Find buttons action to attach event
			----------------------------------*/
			$('button[type="button"]').on('click', function(e) {
				$this._toggleRows(this);
			});
		
			/* if sav connected, init some actions
			----------------------------------*/
			if(base_form == 'claimsav') {
			
				/* Init listening header changes
				----------------------------------*/
				this._initHeader();
			
				/* Control nav between pages
				----------------------------------*/				
				this._initNav();
			
				/* Observe changes to autosave
				----------------------------------*/			
				this._observe();
			}
		
			$(window).on('postHeader', function(e, data) {
				$this.onPostHeader(data);
			});
		
			$(window).on('redirect', function(e, url) {
				$this.onRedirect(url);
			});
    },
		
		/* Init listing claims filters
		----------------------------------*/
		
		_initIndex: function() {
			var form 					= $('#form-claim-listing');
			var search_fields = $('.input-search');
			var status 				= $('#status');
			var track 				= $('#track_by');
			var date_sort_btn = $('.date-sort');
			var date_sort 		= $('#date_sort');
			var url_part 			= form.data('url');
			var url 					=  '/fr/efrontend/' + url_part;
			var target 				= $('#claims_listing');
			var uri 					=  '';
		
			search_fields.on('keyup change', function(e) {
				date_sort.value = '';			
				if($('#countries').length) {
					uri = '&countries=' + $('#countries').val().toString() + '&status=' + status.val().toString() + '&track_by=' + track.val();
				}
				else {
					uri = '&status=' + status.val() + '&track_by=' + track.val();
				}				
				$.ajax({
					url: url,
					data: form.serialize() + uri,
					method: 'post',
					success: function(response) {
						target.html(response);
						_onPreventDelete();					
					}
				});
			});
			
			function _onPreventDelete () {
				$('a.m--supp').on('click', function(e) {
					var mess_confirm = $(this).data('confirm')?$(this).data('confirm'):'Etes-vous sûr de vouloir supprimer ?';
					if (!confirm(mess_confirm)) {
						return false;
					}
				});				
			}						
			_onPreventDelete();
    },
		
		/* Inituser agent and model selector, in add claim - SAV
		----------------------------------*/
		_initPRODUCTADD: function() {
			// console.log('_initPRODUCTADD');
			// Test in case base form is dealer 
			if($('#claim_agent_selector')) {
				var agent_select 	= $('#claim_agent_selector');
				var agent_wrapper = $($('#wrapper_claimant_name'));
				//fields to update;
				var f_name 		= $($('#claimant_name'));
				var f_code 		= $($('#claimant_code'));
				var f_type 		= $($('#claimant_level'));
				var f_address	= $($('#claimant_address'));
				var f_country	= $($('#claimant_country'));
				var f_phone		= $($('#claimant_phone'));
				var f_contact	= $($('#claimant_contact'));
				var f_email		= $($('#claimant_email'));
				var _url = this.base_url + 'ajax/case/user/uid/';
				
				agent_select.on('change', function(e) {
					var $el = $(this);
					if($el.val() == -1) {
						agent_wrapper.addClass('active');
					}
					else {
						if($el.val() !='' && $el.val() > 0) {
							$.ajax({
								method: 'get',
								url: _url + $el.val(),
								dataType: 'json',
								success: function(response) {
									f_name.val(response.f_name);
									f_code.val(response.f_code);
									f_type.val(response.f_type == 0 ? 1 : response.f_type).change();
									f_address.val(response.f_address);
									f_country.val(response.f_country);
									f_phone.val(response.f_phone);
									f_contact.val(response.f_contact);
									f_email.val(response.f_email);
									agent_wrapper.removeClass('active');
								}
							});
						}
					}
				});
			}
				
			// Listen to repairer selects
			this._initRepairerInfos();
			
			if($('#claim_model_selector')) {
				var gamut = $($('#gamut'));
				var model = $($('#model'));
				
				$('#claim_model_selector').on('change', function(e) {
					var value = $(this).val().split('|');
						gamut.val(value[0]);
						model.val(value[1]);
				});
			}
		},
		
		/* Add listening on repairer selects
		----------------------------------*/
		_initRepairerInfos: function() {
			// console.log('_initRepairerInfos');
			if($('#repairer_name')) {
				var rep_select 	= $('#repairer_name');
				var rep_type 		= $($('#repairer_type'));
				var rep_code 		= $($('#repairer_code'));
				var rep_contact = $($('#repairer_contact'));				
				var _url = this.base_url + 'ajax/case/user/uid/';
			
				rep_select.on('change', function(e) {
					if($(this).val() !='' && $(this).val() > 0) {
						$.ajax({
							method: 'get',
							url: _url + $(this).val(),
							dataType: 'json',
							success: function(response) {
								rep_type.val(response.f_type == 0 ? 1 : response.f_type).change();
								rep_code.val(response.f_code);
								rep_contact.val(response.f_contact);
							}
						});						
					}
				});					
			}			
		},
		
		/* Header Edition
		----------------------------------*/
		_initHeader: function () {
			var $form 	 = $('#claimsav-edit-header');
			var $inputs  = $form.find('input[type="text"]');
			var $selects = $form.find('select');
					
			$inputs.on('blur', function(e) {
				$(window).trigger('postHeader', $form);
			});
			
			$selects.on('change', function(e) {
				$(window).trigger('postHeader', $form);
			});
		},
		
		onPostHeader: function (form) {
			$.ajax({
				url: this.base_url + 'editheader',
				method: 'post',
				data: $(form).serialize()
			});
		},
	
		onRedirect: function (url) {
			window.location = url;
		},
		
		/* Nav - Update current form if needed, before redirect to called page.
		----------------------------------*/
		_initNav: function () {
			var $form = this.form;
			var $tabs = $('.claim-tabs a');
			var currentVal = null;
			
			// Add auto clear when value is zero
			$($form).find('input').on('click', function(e) {
				if($(this).val() == 0) this.value = '';
			});
				
			$tabs.on('click', function(e) {
				e.preventDefault();
				var needUpdate = $('#needUpdate').val();
				var target_url = $(this).attr('href');
				if(needUpdate == 1){
					var $data = $($form).serialize();
					var $url = $($form).attr('action');
					$.ajax({
						url: $url,
						method: 'post',
						data: $data,
						success: function(response) {
							$(window).trigger('redirect', target_url);
						}
					});
				}
				else {
					$(window).trigger('redirect', target_url);
				}
			});
		},
		
		/* Register new XDC
		----------------------------------*/
		_initREGISTER: function () {				
			// console.log('_initREGISTER');
			var $this 			= this;
			var $xdchull		= $('#xdc_hull') || null;
			var $product		= $('#product_id') || null;
			var $xdmissing 	= $('#missing_xdc') || null;
			var $submit			= $('.warranty-register-submit');
			var $messages 	= $('.selling-case').find('.toggle--view');
			
			$('#trigger_claim-product').on('change', function(e) {
				$('#step').val(1);
				$('.claim-start').find('.toggle--view').removeClass('active');
				$('#trigger_is-vendor').val('');
				resetfields();
				
				/* Register type accessories
				----------------------------------*/
				if($(this).val() == 'claim-case1') {
					$('#register_type').val('register_access');
					$('.toggle--view').removeClass('active');
					$xdchull.attr('required', false);
					$submit.addClass('active');
				}
				
				/* Register type boat
				----------------------------------*/
				if($(this).val() == 'claim-case2') {
					$('#register_type').val('register_boat');
					$('.claim-product').find('.toggle--view:not(#missing_xdc)').addClass('active');
					$xdchull.attr('required', true);
				
					$xdchull.on('change keyup input', function(e) {
						$('#trigger_is-vendor').val('');
						resetfields();
					});
					
					$('#trigger_is-vendor').on('change', function(e) {
						$('.is-vendor').find('.toggle--view').removeClass('active');
						resetfields();
						
						if($(this).val() == 'product-case1') {
							$submit.addClass('active');
						}
						
						if($(this).val() == 'product-case2') {
							/* First check if xdc exists in warranty contracts
							----------------------------------*/
							if($xdchull.val() != '')	{		
								$.ajax({
									url: $this.base_url + 'ajax/case/get_xdc/xdchull/' + $xdchull.val(),
									method: 'get',
									success: function(response) {
										if(false != response && response > 0) {
											$($product).val(response);
											$submit.addClass('active');
										}
										else {
											$('.is-vendor').find('.toggle--view').addClass('active');
											$('#trigger_selling-case').on('change', function(e) {
												$messages.removeClass('active');
												if($(this).val() == 'vendor-case1' || $(this).val() == 'vendor-case3') {
													$($messages[0]).addClass('active');
												}												
												if($(this).val() == 'vendor-case2') {
													$($messages[1]).addClass('active');
												}								
											});
										}										
									}
								});
							}
							
							else {
								$xdmissing.addClass('active');
							}
						}						
					});					
				}				
			});
			
			function resetfields() {				
				$submit.removeClass('active');
				$messages.removeClass('active');
				$('#trigger_selling-case').val('');
				if($xdchull.val() != '')
					$xdmissing.removeClass('active');
			}
		},		
		
		/* Warranty section 
		----------------------------------*/
		_initWARRANTY: function () {
			$('.js-popup-codes').on('click', function(e) {
				e.preventDefault();
				var $src = $(this).data('src');
				$.magnificPopup.open({
					type: 'iframe',
					items: {
						src: $src
					}
				});				
			});	
			
			var $this = this;
			
			/* Focus on file Number on load
			----------------------------------*/
			$('#claim_num').focus();
						
			/* On change pc_rate, update pc_time value
			----------------------------------*/
			$('#pc_rate').on('change', function(e) {
				var $idx = $(this).val();
				$.ajax({
					url: $this.base_url + 'ajax/case/ratepc/id/' + $idx,
					method: 'get',
					success: function(response) {
						$('#pc_time').val(response);
					}
				});
			});
			
			/* Refresh selectors inside added rows
			----------------------------------*/
			this._setSelectors('sel_xchange_rate');		
		},
		
		/* Product section 
		----------------------------------*/
		_initPRODUCT: function () {
			// Listen to repairer selects
			this._initRepairerInfos();
		},
		
		/* Medias section 
		----------------------------------*/
		_initMEDIAS: function () {
			if(this.control_medias) {
				// On submit, check if photo grid is not empty;				
				$(this.form).on('submit', function(e) {
					$message = '';
					$('*[data-alert]').each( function() {
						var $element = $(this).find('.dz-image-preview');
						if($element.length == 0) {
							$message += '- ' + $(this).data('alert') + "\n";
						}					
					});
					if($message.length > 0) {
						alert($message);
						return false;
					}		
				});				
			}
		},
		
		/* Infocentre - reglement
		----------------------------------*/
		_initINFOCENTRE: function () {
			var $this = this;
			this.INFOCENTRE  = true;
		
			$(window).on('updateAmounts', function(e) {
				var $assets 		 = $('.asset-amount');
				var $info_assets = $('#infos-payment-assets');
				var $total_1 	 	 = $info_assets.data('total');
				var $sum_assets  = 0;
				var $invoices 		 = $('.invoice-amount');
				var $info_invoices = $('#infos-payment-invoices');
				var $total_2 			 = $info_invoices.data('total');
				var $sum_invoices  = 0;
				// Check assets
				$assets.each(function(i) {
					$sum_assets += parseFloat($(this).val());					
				});									
				if($sum_assets != 0 && parseFloat($sum_assets).toFixed(2) != parseFloat($total_1).toFixed(2)) {
					$info_assets.find('.message').addClass('alert');
				}
				else {
					$info_assets.find('.message').removeClass('alert');
				}
				// Check invoices
				$invoices.each(function(i) {
					$sum_invoices += parseFloat($(this).val());					
				});								
				if($sum_invoices != 0 && parseFloat($sum_invoices).toFixed(2) != parseFloat($total_2).toFixed(2)) {
					$info_invoices.find('.message').addClass('alert');
				}
				else {
					$info_invoices.find('.message').removeClass('alert');
				}
			});
			
		},
		
		/* Messages section 
		----------------------------------*/
		_initMESSAGE: function () {
		},	
	
		/* Set / refresh selectors
		----------------------------------*/
		_setSelectors: function (target) {	
			var $this = this;
			
			if(target == 'sel_xchange_rate') {
				$('.exchange_rate').on('change', function(e) {					
					var $tid = $(this).data('target-id');
					var $val = $(this).val();
					$.ajax({
						url: $this.base_url + 'ajax/case/xchange/id/' + $val,
						method: 'get',
						success: function(response) {
							$($('.exchange_cost')[$tid]).val(response);
						}
					});					
				});
			}
		},
	
		/* Generic add/remove rows
		----------------------------------*/
		_toggleRows: function (obj) {
			var $this = this;
			var $tmp = $(obj).data('action').split('-');
			var $action = $tmp[0];
			var $target = $tmp[1];
			var rows = parseInt($('#'+$target).find('tr').length);
					
			if($action == 'add') {
				var $url = this.base_url + 'ajax/case/' + $target + '/rows/' + rows, tr;
				$.ajax({
					url: $url,
					method: 'get',
					dataType: 'json',
					success: function(response) {
						/* add new costs row
						----------------------------------*/
						if($target == 'claim_process_costs') {
							tr = $('<tr valign="top"></tr>').html(response);
							tr.find('button').click(function() {tr.remove();});							
							$('#'+$target).append(tr);
							$this._setSelectors('sel_xchange_rate');	
						}
						/* add new message
						----------------------------------*/
						else if($target == 'claim_messages'){
							tr = $('<tr class="message-body" valign="top"></tr>').html(response);
							$('#'+$target).prepend(tr);
						}
						else {
							tr = $('<tr valign="middle"></tr>').html(response);
							tr.find('button').click(function() {tr.remove();});
							$('#'+$target).append(tr);
						}							
					}
				});				
			}
			
			if($action == 'rem') {
				if($target == 'claim_default_codes') {
					var row2 = $('#'+$(obj).data('code-rep')).closest('tr');
							if(row2) row2.remove();
					var row3 = $(obj).closest('tr');
							row3.remove();
				}
				else if($target == 'claim_process_costs') {
					var row4 = $(obj).closest('tr');
							row4.remove();
				}
				else {
					var row5 = $('#'+$target).last('tr');
							row5.remove();
				}				
			}
			// Observe new fields
			this._observe();
		},
		
		/* Observe changes in select, input, textarea
		----------------------------------*/	
		_observe: function(obj) {
			var $this = this;		
			this.form.on('keyup change paste', 'input, select, textarea', function(){
				$('#needUpdate').val(1);				
				// Special case for Amounts sum in Infocentre
				if($this.INFOCENTRE) {	
					setTimeout(function () { $(window).trigger('updateAmounts'); }, 700);			
				}				
			});			
		},
		
		/* Codes defaults - called from popup code - iframe
		----------------------------------*/		
		_addCodeDefault: function(obj){
			var $claimant_code	= $($('#claimsav-edit-header')).data('claimant-idx');
			var t_code 	 	= $('#claim_default_codes');
			var t_repair 	= $('#claim_process_repairs');
			var code_id 	= $(obj).data('id');
			var code_lang = $(obj).data('lang');
			var rows 			= t_code.find('tr').length;
			var url 			= '/fr/efrontend/navigate_claimsav/ajax/case/claim_default_codes/dealercode/' + $claimant_code + '/rows/' + rows + '/codeid/' + code_id + '/lang/' + code_lang;
			$.ajax({
				url: url,
				method: 'get',
				dataType: 'json',
				success: function(response) {
					var tr2 = $('<tr></tr>').html(response.def);
					var tr  = $('<tr valign="top"></tr>').html(response.code);
							tr.find('button').click(function() {tr.remove();tr2.remove();});
					$(t_code).append(tr);
					$(t_repair).append(tr2);
					$.magnificPopup.instance.close();
				}
			});
		},
  });
  // ---------- END

})(jQuery);