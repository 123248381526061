(function($){

// ---------- APP START
window.App = {

  config: {
    scrollOffset: 70
  },

  init: function(){
    // Init SVG polyfill
    if(typeof(svg4everybody) !== 'undefined') svg4everybody();
    // Init object-fill polyfill
    if(typeof(objectFitImages) !== 'undefined') objectFitImages();
    // Remove focus state when clicking on a button with a mouse
    $(':not(form) button, a').on('click', function(e){
      if(e.clientX && e.clientY)
        $(this).blur();
    });
		
    $('a[href=""], a[href="#"]').on('click.nohref', function(e){
      e.preventDefault();
    });

    $('a[href^="#"]').not('.js-no-scroll').not('[href="#"]').not('[href="#rgpd"]').on('click.scroll', function(e){
      e.preventDefault();
      App.scroll($(this).attr('href'));
    });
		
		/* Common actions
		----------------------------------*/
		$('.autosubmit').on('change', function(e) {
			this.closest('form').submit();
		});
		
		$('.zaboats').on('click', function(e) {
			e.preventDefault();
			window.open('https://zaboats.idapps.app');
		});
		
		$('p.success').each(function () {
			$(this).fadeOut(3500);
		});
		
		$('a.suppress').on('click', function(e) {
			var mess_confirm = $(this).data('confirm')?$(this).data('confirm'):'Etes-vous sûr de vouloir supprimer ?';
			if (!confirm(mess_confirm)) {
				e.stopImmediatePropagation();
        e.preventDefault();
			}
		});
		
		$('a.m--supp').on('click', function(e) {
			//e.preventDefault();
			var mess_confirm = $(this).data('confirm')?$(this).data('confirm'):'Etes-vous sûr de vouloir supprimer ?';
			if (!confirm(mess_confirm)) {
				e.stopImmediatePropagation();
        e.preventDefault();
			}
		});
		
		/* Claims - Delete item
		----------------------------------*/		
		$('a.ajax').on('click', function(e) {
			e.preventDefault();
			var $link = $(this);
			if($link.hasClass('delete')) {
				var mess_confirm = $link.data('confirm')? $link.data('confirm'):'Etes-vous sûr de vouloir supprimer ?';	
				if(confirm(mess_confirm)) {					
					$.ajax({
						url: $link.attr('href'),
						success: function(response) {
							$link.closest('.bloc-claim-media').parent().remove();
						}
					});
				} else {
					return false;
				}				
			}
		});
		
		/* Multi selects
		----------------------------------*/		
		$('select[multiple]').multiselect();
		
		/* Init functions
		----------------------------------*/			
		if($('#sparepart-search').length) App.webshop.init();
		if($('.js-popup-img').length) App.mfp.initPopupImage();
		if($('section.warranty-contracts').length) App.warranty.initSearch();
		if($('section.warranty-edit').length) App.warranty.initContract();
		if($('section.claims').length) $('section.claims').navigate();
		
		if(typeof Dropzone != 'undefined') {
			Dropzone.autoDiscover = false;
			App.initDropzone();
		}
		
		/* Set widget accessible from document
		----------------------------------*/
		App.widget = $('section.claims').navigate("instance");
  },

  load: function(){
    if ($(window).width() >= 768) {
      App.config.scrollOffset = 120;
    }
    if (window.location.hash && window.location.hash.indexOf('#!') < 0 && $(window.location.hash).length) App.scroll(window.location.hash);
  },

  scroll: function(id, offset){
    if (id != '#' && $(id).length) {
      offset = offset === undefined ? App.config.scrollOffset : offset;
      $('html, body').animate({ scrollTop: $(id).offset().top - offset}, 'slow');
    }
  },

  mfp: {
		initPopupImage: function(){			
      $('.js-popup-img').magnificPopup({
        type: 'image',
      });
    }
	},
	
	initDropzone: function(){
		
		Dropzone.options.claimDropzone = {
			paramName: "file", // The name that will be used to transfer the file
			createImageThumbnails:false
		};
		
		$('.dz-uploader').each(function (el) {
			var tmp = '';
			var $dropzone = 'div#' + $(this).attr('id');
			var $url 			= $(this).data('url');
			var $maxFiles = $(this).data('maxfiles');
			var $ui 			= $(this).data('ui') || null;
			var $fileList = document.getElementById($(this).data('callback'));
			var $nopreview = $ui == 'simple' ? true:false;
			
			new Dropzone($dropzone, {
				addRemoveLinks: true,
				//disablePreviews: $nopreview,
				url: $url,
				dictDefaultMessage: $(this).data('label'),
				maxFilesize: 800,
				maxFiles: $maxFiles,
				//clickable: '.fileupload-button',
				renameFile: function(file) {
					return slugify(file.name,{lower: true});
				},	
				success: function(file, filename,e) {
					tmp += ',' + filename;
					$($fileList).val(tmp.substring(1));
				}
			});
		});
	},
	
	webshop: {
		
		init: function(){
			
			var $form 		= $('#sparepart-search');
			var $term 		= $('#art-code');
			var $card 		= $('#part-view');
			var $search 	= $('#search-code');
			var $lang 		= $form.data('lang');
			
			$search.on('click', function(e) {
				if($term.value != '') {
					$.ajax({
						url: '/' + $lang + '/efrontend/spareparts/addselection/code/' + $term.val(),
						success: function(response) {
							$card.html(response);
							shoppingCartActions();
						}
					});
				}
			});
			
			shoppingCartActions();
			
			function shoppingCartActions () {
				$('.additem').on('click', function(e) {
					e.preventDefault();
					var $btn = $(this);					
					$.ajax({
						url: '/' + $lang + '/efrontend/spareparts/additem/id/' + $btn.data('id'),
						success: function(response) {
							var lib = $btn.data('alt');
							$btn.parent().html('<span>'+lib+'</span>');
						}
					});
				});
			}			
		}		
	},
	
	warranty: {
		
		initSearch: function(){
			/* Warranty listing - Search by xdc
			----------------------------------*/		
			$('#search-xdc').autocomplete({
				source: "/fr/efrontend/warranty/searchxdc",
				minLength: 2,
				select: function( event, ui ) {
					$("#hidden-xdc").val(ui.item.id);
				}
			});			
		},
		
		initContract: function(){
			/* Toggle motor fields
			----------------------------------*/	
			$('.toggle--view').each(function () {
			 var $bloc = $(this);
			 var $trigger = "#" + $(this).data('trigger');
			 
			 $($trigger).on('change', function(e) {
				 if(
				 	($(this).attr('id') == 'motortype_st' && $(this).val() > 0) || 
				 	($(this).attr('id') == 'buyertype' && $(this).val() > 1)
				 ) {
					 $bloc.addClass('active');
				 }
				 else {
					 $bloc.removeClass('active'); 
				 }				 
			 });
			 
			});
			
			/* Ajax pre-fill boat selects
			----------------------------------*/
			var $boatrange = $('#boatrange');
			var $boatmodel = $('#boatmodel');
			var $lang 		= $('html').attr('lang');
			
			$('#boatbrand').on('change', function(e){
				var $brand = $(this).val();
					  $boatmodel.empty();
				$.ajax({
					url: '/' + $lang + '/efrontend/warranty/ajax/brand/' + $brand,
					success: function(response) {
						$boatrange.html(response);
					}
				});	
			});
			
			$boatrange.on('change', function(e){
				var $brand 	 = $('#boatbrand').val();
				var $rangeid = $('#boatrange').val();
				$.ajax({
					url: '/' + $lang + '/efrontend/warranty/ajax/brand/' + $brand + '/range/' + $rangeid,
					success: function(response) {
						$boatmodel.html(response);
					}
				});
			});
		}		
	}
};
// ---------- APP END

// Init app
// App.initTAC();
$(document).ready(App.init);
$(window).on('load', App.load);
})(jQuery);